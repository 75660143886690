<template>
    <div class="MobAccountsecurity">
        <!-- 账户安全 -->
        手机版暂为开放
    </div>
</template>

<script>
export default {
    name:"MobAccountsecurity"
}
</script>

<style lang="less">
// .MobAccountsecurity{}
</style>